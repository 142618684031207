.flip {
  width: 300px;
  height: 200px;   
  text-align: center;
  perspective: 600px;  
}
.flip-content {
  width: 100%;
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.6s;
}

.flip-front, .flip-back {
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 150px;
  backface-visibility: hidden;  
  color: #fff;  
  /* background-color: rgb(91, 165, 205); */
  /* border: 6px solid lightcoral; */
  /* box-shadow: 6px 6px rgb(91, 165, 205); */
}
.flip-back {
  transform: rotateY(180deg);
  /* border: 6px solid lightcoral; */
  /* box-shadow: 6px 6px lightcoral; */
}